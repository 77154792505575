import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const WorkMidCopy = () => {
  const headCopyRef = useRef(null);

  useEffect(() => {
    const spans = gsap.utils.toArray(".head span", headCopyRef.current);
    spans.forEach((span) => {
      gsap.fromTo(
        span,
        { scale: 0.2 }, // 시작할 때 스케일
        {
          scale: 1, // 종료할 때 스케일
          scrollTrigger: {
            trigger: span,
            start: "top bottom", // 트리거가 뷰포트 하단에 도달했을 때 시작
            end: "center center", // 트리거가 뷰포트 중앙에 도달했을 때 종료
            scrub: true, // 스크롤 바를 움직이면서 애니메이션 효과가 부드럽게 적용됩니다
            markers: false, // 애니메이션의 시작과 끝을 표시하는 마커
            ease: "elastic.out(1,0.3)",
          },
        }
      );
    });
  }, []);

  return (
    <div ref={headCopyRef}>
      <div className="first-copy">
        <p className="head">
          <span>규칙은 간결하고</span>
          <span>기술은 유연합니다</span>
        </p>
        <p className="sub">Rules are concise, and technology is flexible</p>
      </div>
      <div className="second-copy">
        <p className="head">
          <span>기술의 한계를 넘어서세요.</span>
          <span>함께라면 가능합니다</span>
        </p>
        <p className="sub">
          Push beyond the limits of technology. With Routebase, it's possible.
        </p>
      </div>
    </div>
  );
};

export default WorkMidCopy;
