export const aiProductList = [
  {
    id: 1,
    title: "",
    imgURL: "/images/ai_prod_01.jpg",
  },
  {
    id: 2,
    title: "",
    imgURL: "/images/ai_prod_02.jpg",
  },
  {
    id: 3,
    title: "",
    imgURL: "/images/ai_prod_03.jpg",
  },
  {
    id: 4,
    title: "",
    imgURL: "/images/ai_prod_04.jpg",
  },
  {
    id: 5,
    title: "",
    imgURL: "/images/ai_prod_05.jpg",
  },
  {
    id: 6,
    title: "",
    imgURL: "/images/ai_prod_06.jpg",
  },
  {
    id: 7,
    title: "",
    imgURL: "/images/ai_prod_07.jpg",
  },
  {
    id: 8,
    title: "",
    imgURL: "/images/ai_prod_08.jpg",
  },
  {
    id: 9,
    title: "",
    imgURL: "/images/ai_prod_09.jpg",
  },
  {
    id: 10,
    title: "",
    imgURL: "/images/ai_prod_10.jpg",
  },
  {
    id: 11,
    title: "",
    imgURL: "/images/ai_prod_11.jpg",
  },
  {
    id: 12,
    title: "",
    imgURL: "/images/ai_prod_12.jpg",
  },
  {
    id: 13,
    title: "",
    imgURL: "/images/ai_prod_13.jpg",
  },
  {
    id: 14,
    title: "",
    imgURL: "/images/ai_prod_14.jpg",
  },
  {
    id: 15,
    title: "",
    imgURL: "/images/ai_prod_15.jpg",
  },
  {
    id: 16,
    title: "",
    imgURL: "/images/ai_prod_16.jpg",
  },
  {
    id: 17,
    title: "",
    imgURL: "/images/ai_prod_17.jpg",
  },
  {
    id: 18,
    title: "",
    imgURL: "/images/ai_prod_18.jpg",
  },
];
