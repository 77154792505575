import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";

import "./assets/css/App.scss";
import Layout from "pages/Layout";
import Main from "pages/Main";
import Works from "pages/Works";
import Services from "pages/Services";
import About from "pages/About";
import Feeds from "pages/Feeds";
import Contact from "pages/Contact";
import NoPage from "pages/NoPage";

export default function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/contact" || location.pathname === "/feeds") {
      document.body.classList.add("is-bg-gray");
    } else {
      document.body.style.backgroundColor = "";
      document.body.classList.remove("is-bg-gray");
      document.body.classList.remove("is-bg-darkgray");
    }
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          <Route path="/works" element={<Works />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/feeds" element={<Feeds />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </>
  );
}
