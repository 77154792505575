import React from "react";

const GalleryBox = React.memo(({ imageList }) => {
  // \n 문자를 <br> 태그로 변환하는 함수
  const formatText = (text) => {
    return { __html: text.replace(/\n/g, "<br>") }; // \n을 <br>로 대체
  };

  return (
    <>
      <div className="service-ai-gallery">
        {imageList.map((item, index) => (
          <div key={index} className={item.isTextbox ? "textbox" : ""}>
            {item.isTextbox ? (
              // HTML 문자열을 안전하게 삽입
              <p dangerouslySetInnerHTML={formatText(item.text)} />
            ) : (
              <img src={item.imgURL} alt={item.title} />
            )}
          </div>
        ))}
      </div>
    </>
  );
});

export default GalleryBox;
