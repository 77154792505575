export const aiImageList = [
  {
    id: 1,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_01.jpg",
  },
  {
    id: 27,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_24.jpg",
  },
  {
    id: 3,
    isTextbox: true,
    text: "AI 기술로 완성된 소셜미디어용\n모델 이미지를 제공합니다",
  },
  {
    id: 4,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_03.jpg",
  },
  {
    id: 5,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_04.jpg",
  },
  {
    id: 6,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_05.jpg",
  },
  {
    id: 7,
    isTextbox: true,
    text: "패션과 헤어를 강조한\n고퀄리티 AI 모델 이미지를\n제공해 드립니다",
  },
  {
    id: 8,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_06.jpg",
  },
  {
    id: 9,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_07.jpg",
  },
  {
    id: 10,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_08.jpg",
  },
  {
    id: 11,
    isTextbox: true,
    text: "뷰티와 패션을 위한\n맞춤형 이커머스 AI 모델을 만나보세요",
  },
  {
    id: 12,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_09.jpg",
  },
  {
    id: 24,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_21.jpg",
  },
  {
    id: 14,
    isTextbox: true,
    text: "당신의 제품에 딱 맞는 배경 이미지를\nAI로 제작합니다",
  },
  {
    id: 15,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_12.jpg",
  },
  {
    id: 16,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_13.jpg",
  },
  {
    id: 17,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_14.jpg",
  },
  {
    id: 18,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_16.jpg",
  },
  {
    id: 19,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_17.jpg",
  },
  {
    id: 20,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_18.jpg",
  },
  {
    id: 21,
    isTextbox: true,
    text: "이커머스 비즈니스를 위한\n최적의 AI 모델 솔루션을 경험하세요",
  },
  {
    id: 2,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_02.jpg",
  },
  {
    id: 23,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_19.jpg",
  },
  {
    id: 25,
    isTextbox: false,
    title: "AI-Powered Visualizations",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgURL: "/images/gen_ai_22.jpg",
  },
];
