const ServiceTitleVisionFlow = () => {
  return (
    <>
      <h2 className="service-tit">VISIONFLOW</h2>
      <p className="service-sub-desc">
        비전플로우는 라우트베이스에서 만든 생성형 AI 이미지 서비스
        브랜드네임입니다.
      </p>
      <div className="service-ai-box">
        <div className="">
          콘텐츠와 상품을 연결하는
          <br />
          AI 이미지 서비스 최적화
        </div>
        <div className="imgbox-content">컨텐츠 재생산화</div>
      </div>
    </>
  );
};

export default ServiceTitleVisionFlow;
