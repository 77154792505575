import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const AboutFocus = ({}) => {
  const focusRef = useRef(null);

  useEffect(() => {
    const spans = gsap.utils.toArray(".about-focus span", focusRef.current);
    spans.forEach((span) => {
      gsap.fromTo(
        span,
        { scale: 0.2 }, // 시작할 때 스케일
        {
          scale: 1, // 종료할 때 스케일
          scrollTrigger: {
            trigger: span,
            start: "top bottom", // 트리거가 뷰포트 하단에 도달했을 때 시작
            end: "center center", // 트리거가 뷰포트 중앙에 도달했을 때 종료
            scrub: true, // 스크롤 바를 움직이면서 애니메이션 효과가 부드럽게 적용됩니다
            markers: false, // 애니메이션의 시작과 끝을 표시하는 마커
            ease: "elastic.out(1,0.3)",
          },
        }
      );
    });
  }, []);

  return (
    <h4 ref={focusRef} className="about-focus">
      <span>THE WHAT</span>
      <span>BETWEEN</span>
      <span>UNIQUENESS</span>
      <span>AND</span>
      <span>AVERAGE</span>
    </h4>
  );
};

export default AboutFocus;
