import Header from "components/Header";
import Footer from "components/Footer";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <>
      <div className="app-wrap">
        <Header isMain={true}></Header>
        <div className="app-container">
          <Outlet />
        </div>
        <Footer isMain={true} />
      </div>
    </>
  );
}

export default Layout;
