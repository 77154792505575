import React, { useState, useRef, useEffect } from "react";

function YearBox({ setSelectedYear }) {
  const startYear = 1999;
  const endYear = 2024;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );
  const [activeYear, setActiveYear] = useState(2024);
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("wheel", handleWheel, { passive: false });

    setSelectedYear(activeYear); // 선택된 연도를 상위 컴포넌트로 전달
    return () => {
      container.removeEventListener("wheel", handleWheel);
    };
  }, [activeYear, setSelectedYear]);

  const handleWheel = (event) => {
    event.preventDefault();
    const direction = event.deltaY > 0 ? -1 : 1;
    const currentIndex = years.indexOf(activeYear);
    const nextIndex = Math.max(
      0,
      Math.min(years.length - 1, currentIndex + direction)
    );
    setActiveYear(years[nextIndex]);
  };

  const getStyle = (year) => {
    const index = years.indexOf(year);
    const selectedIndex = years.indexOf(activeYear);
    const offset = selectedIndex - index;
    const opacity = Math.max(0, 1 - 0.2 * Math.abs(offset));
    // const topPosition = 33.33 + offset * 33.33; // 각 연도의 위치 조정
    const topPosition = 20 + offset * 16; // 각 연도의 위치 조정

    return {
      opacity,
      position: "absolute",
      top: `${topPosition}%`,
      left: "50%",
      fontSize: offset === 0 ? "48px" : "36px",
      transform: "translateX(-50%)",
      transition: "opacity 0.3s, font-size 0.3s, top 0.3s",
    };
  };

  return (
    <div
      ref={containerRef}
      onWheel={handleWheel}
      style={{
        position: "relative",
        width: "100%",
        height: "400px",
        overflow: "hidden",
      }}
    >
      {years.map((year) => (
        <div key={year} style={getStyle(year)}>
          {year}
        </div>
      ))}
    </div>
  );
}

export default YearBox;
