import GINZA from "assets/images/contact_img_01.webp";
import COFFEE from "assets/images/contact_img_02.webp";
import LOGO_RB from "assets/images/logo_rb.svg";

const Contact = () => {
  return (
    <>
      <div className="app-contact">
        <h2 className="header-copy">
          <span className="sub-text">낮설음으로 시작된 인연</span>
          <div className="main-text">
            A relationship that
            <br />
            began with unfamiliarity
          </div>
        </h2>
        <div className="contact-imgbox">
          <div>
            <figure>
              <img src={GINZA} alt="Ginza" />
              <figcaption>
                긴자의 상징. 와코백화점 세이코 시계탑을 봐라보며
              </figcaption>
            </figure>
          </div>
          <div>
            <figure>
              <img src={COFFEE} alt="coffee" />
              <figcaption>장마철 아인슈페너로 한 잔 먹음고</figcaption>
            </figure>
          </div>
        </div>
        <div className="contact-guide">
          Want to connect with our team?
          <br />
          Start here!
          <br />
          Get in touch with us at the email below
        </div>
        <div className="contact-infobox">
          <div className="contact-logo">
            <img src={LOGO_RB} className="app-logo" alt="Routebase" />
          </div>
          <dl>
            <dt>t</dt>
            <dd>+82(0)10 9934 0612 </dd>
          </dl>
          <dl>
            <dt>e</dt>
            <dd>
              <a href="mailto:routebasegroup@gmail.com">
                routebasegroup@gmail.com
              </a>
            </dd>
          </dl>
        </div>
        <div className="contact-bottom-desc">
          Routebase is your compass, navigating you through the complexities of
          the digital world.
          <br />
          Let us guide your next big idea to success with cutting-edge
          technology
          <br />
          and innovative solutions that stand out in the market.
        </div>
        <div className="contact-bottom-define">
          #ROUTEBASE IS A DIGITAL SERVICE GROUP BASED IN SEOUL, SOUTH KOREA
          #ROUTEBASE IS A DIGITAL SERVICE GROUP BASED IN SEOUL, SOUTH KOREA
        </div>
      </div>
    </>
  );
};

export default Contact;
