import React from "react";
import { aiImageList } from "data/aiImageList";
import { aiProductList } from "data/aiProductList";
import ServiceFrontEnd from "components/ServiceFrontEnd";
import ServiceTitleVisionFlow from "components/ServiceTitleVisionFlow";
import GalleryBox from "components/GalleryBox";
import MovingGallary from "components/MovingGallary";
import ServiceSwiper from "components/ServiceSwiper";

const Services = () => {
  return (
    <>
      <div className="app-services">
        <section className="service-frontend-wrap">
          <ServiceFrontEnd />
        </section>
        <section className="service-ai-wrap">
          <ServiceTitleVisionFlow />
          <GalleryBox imageList={aiImageList} />
          <MovingGallary />
          <ServiceSwiper productList={aiProductList} />
        </section>
      </div>
    </>
  );
};

export default Services;
