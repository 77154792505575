import React, { useRef, useEffect } from "react";
import { useStore } from "stores/store";
import { useScroll } from "hooks/use-scroll";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const MovingGallary = () => {
  const lenis = useStore(({ lenis }) => lenis);

  useScroll(ScrollTrigger.update);

  const thumRefs = useRef([]);
  thumRefs.current = [];

  useEffect(() => {
    thumRefs.current.forEach((thum, index) => {
      const depth = (index + 1) * 300; // 각 요소의 y 이동 거리를 크게 조정
      gsap.fromTo(
        thum,
        { y: 0 },
        {
          y: () => `-${depth}px`, // 화살표 함수를 사용해 각 요소마다 다른 깊이 효과 적용
          scrollTrigger: {
            trigger: thum,
            start: "top bottom+=100", // 트리거가 뷰포트 상단 아래로 100px 지점에 있을 때 시작
            end: () => `+=${depth + 500}`, // 종료 지점도 확장
            scrub: true,
            // markers: true, // 개발 중 위치 확인을 위한 마커 표시
            ease: "power4.out",
          },
        }
      );
    });
  }, []);

  const addToRefs = (el) => {
    if (el && !thumRefs.current.includes(el)) {
      thumRefs.current.push(el);
    }
  };

  return (
    <div className="move-thum-wrap">
      <div ref={addToRefs} className="move-thum-txt">
        뷰티, 헤어, 제품 스타일 혁신을 경험하세요
        <br />— AI 모델이 실현하는 아름다움
      </div>
      <div ref={addToRefs} className="move-thum-01">
        <img src="/images/gen_ai_22.jpg" alt="라우트베이스" />
      </div>
      <div ref={addToRefs} className="move-thum-02">
        <img src="/images/gen_ai_23.jpg" alt="라우트베이스" />
      </div>
      <div ref={addToRefs} className="move-thum-03">
        <img src="/images/gen_ai_26.jpg" alt="라우트베이스" />
      </div>
      <div ref={addToRefs} className="move-thum-04">
        <img src="/images/gen_ai_25.jpg" alt="라우트베이스" />
      </div>
    </div>
  );
};

export default MovingGallary;
