import React, { useRef, useEffect } from "react";
import gsap from "gsap";

const AboutCopy = ({}) => {
  const firstLineRef = useRef(null);
  const secondLineRef = useRef(null);
  var delayTime = 0.15;

  useEffect(() => {
    const firstLine = "삶의 모든 순간은 찰나이다.";
    const secondLine = "그렇기에 우리는...";

    // 첫 번째 줄 애니메이션 적용
    animateText(firstLine, firstLineRef.current);
    // 두 번째 줄 애니메이션 적용
    animateText(
      secondLine,
      secondLineRef.current,
      firstLine.length * delayTime
    ); // 첫 줄 애니메이션이 끝난 후 시작
  }, []);

  const animateText = (text, container, delay = 0) => {
    const chars = text.split("");
    container.innerHTML = "";
    chars.forEach((char, i) => {
      const span = document.createElement("span");
      span.textContent = char;
      container.appendChild(span);

      gsap.fromTo(
        span,
        {
          opacity: 0,
          y: "100%",
        },
        {
          opacity: 1,
          y: "0%",
          delay: delay + i * delayTime,
          ease: "power2.out",
        }
      );
    });
  };

  return (
    <>
      <div ref={firstLineRef} className="copy first-copy"></div>
      <div ref={secondLineRef} className="copy second-copy"></div>
    </>
  );
};

export default AboutCopy;
