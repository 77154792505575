import P from "assets/images/letter_p.png";
import R from "assets/images/letter_r.png";
import O from "assets/images/letter_o.png";
import J from "assets/images/letter_j.png";
import E from "assets/images/letter_e.png";
import C from "assets/images/letter_c.png";
import T from "assets/images/letter_t.png";

export const projectList = [
  {
    id: "01",
    imgURL: P,
    title: "Etoos CS/HP Institute",
    client: "Etoos",
    role: "Vue UI Screen Development",
    startDt: "Aug 2024",
    endDt: "Oct 2024",
    stack: "Nuxt, Vue3, VueUse, Swiper, GSAP, SCSS, Figma, Jira",
    info: "Conversion HTML to Vue3, UI Component Development",
  },
  {
    id: "02",
    imgURL: R,
    title: "SK C&C Smart Factory MES",
    client: "SK Absolics\nSK C&C",
    role: "Vue Frontend",
    startDt: "Sep 2023",
    endDt: "Nov 2023",
    stack: "Vue 2, I-FACTs Framwork, Wijmo Grid, Buefy",
    info: "Front-end Development for MES\nUI/UX Improvement\nNew Page Development",
  },
  {
    id: "03",
    imgURL: O,
    title: "SCP CMP Monitoring",
    client: "Samsung SDS\nS-Core",
    role: "Vue Frontend",
    startDt: "Oct 2021",
    endDt: "Mar 2023",
    stack: "Vue 2, Lego Component, EChart, SCSS, Git, Jira, Confluence / MSA",
    info: "Development of Detailed Monitoring Target in Vue, Partial Development of Event/Agent",
  },
  {
    id: "04",
    imgURL: J,
    title: "Uniportal Groupware",
    client: "Bizentro",
    role: "UI Screen Development",
    startDt: "May 2018",
    endDt: "Sep 2021",
    stack: "HTML5, JS, dhtmlx, JSP, SCSS",
    info: "Groupware UI Screen Development\nJS Development\nManual Writing\nPublishing Training\nCloud Portal Publishing",
  },
  {
    id: "05",
    imgURL: E,
    title: "Hyundai H&S E-Commerce",
    client: "Hyundai H&S",
    role: "JS Development",
    startDt: "May 2017",
    endDt: "Aug 2017",
    stack: "JS, jQuery, JSP",
    info: "Hybrid Web-based UI Development",
  },
  {
    id: "06",
    imgURL: C,
    title: "Fila Korea ERP",
    client: "Fila Korea",
    role: "UI Screen Development Lead",
    startDt: "May 2016",
    endDt: "Jun 2016",
    stack: "Websquare, CSS, jQuery, jqWidget",
    info: "Distribution ERP WebSquare-based UI Screen Development",
  },
  {
    id: "07",
    imgURL: T,
    title: "KT BIT OSS",
    client: "KT",
    role: "JS Development",
    startDt: "Feb 2013",
    endDt: "Aug 2013",
    stack: "Websquare, JS",
    info: "Common UI JS Design\nJS Plugin Development",
  },
];
