import { React, useState } from "react";
import { projectList } from "data/projectList";
import SelectedProject from "components/SelectedProject";

const KeyProjectBox = () => {
  const [selectedProject, setSelectedProject] = useState(projectList[0]);
  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  return (
    <>
      <div className="work-gallery-list">
        {projectList.map((project, index) => (
          <figure data-workindex={index} className="work-item">
            <figcaption>
              0{index + 1} {project.title}
            </figcaption>
            <div
              className={
                selectedProject && selectedProject.id == project.id
                  ? "active"
                  : ""
              }
              onClick={() => handleProjectClick(project)}
            >
              <img src={project.imgURL} alt={project.title} />
            </div>
          </figure>
        ))}
      </div>
      {selectedProject && <SelectedProject project={selectedProject} />}
    </>
  );
};

export default KeyProjectBox;
