import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const CircleMotion = ({}) => {
  const circlesRef = useRef([]);

  useEffect(() => {
    circlesRef.current.forEach((circle, i) => {
      const initialRotation = i * 40; // 각 원의 초기 회전 각도 계산
      gsap.fromTo(
        circle,
        {
          rotationY: initialRotation, // 초기 회전 각도 설정
        },
        {
          rotationY: initialRotation + 360, // 최종 회전 각도는 초기 각도에 360도 추가
          scrollTrigger: {
            trigger: ".about-circle",
            start: `top+=${i * 50} center`, // 각 원마다 다른 시작점 설정
            end: "bottom top",
            scrub: true,
            markers: false,
            ease: "power4.out",
          },
        }
      );
    });
  }, []);

  return (
    <div className="about-circle">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {Array.from({ length: 5 }).map((_, i) => (
          <div
            key={i}
            ref={(el) => (circlesRef.current[i] = el)}
            style={{
              width: "280px",
              height: "280px",
              backgroundColor: "#1e1e1e",
              borderRadius: "50%",
              transformOrigin: "center",
              transformStyle: "preserve-3d",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CircleMotion;
