import { React, useState, useEffect, useRef } from "react";
import { othersList } from "data/othersList";
import KeyProjectBox from "components/KeyProjectBox";
import WorkMidCopy from "components/WorkMidCopy";
import WorkMidThirdCopy from "components/WorkMidThirdCopy";
import WorkMidBox from "components/WorkMidBox";
import YearBox from "components/YearBox";
import DescBox from "components/DescBox";
import "swiper/css";

const Works = () => {
  const [selectedYear, setSelectedYear] = useState(2024);
  const [projectsForYear, setProjectsForYear] = useState([]);

  useEffect(() => {
    // 선택된 연도에 해당하는 프로젝트를 필터링
    const filteredProjects = othersList.filter(
      (project) => parseInt(project.year) === selectedYear
    );
    setProjectsForYear(filteredProjects);
  }, [selectedYear]);

  return (
    <>
      <div className="app-works">
        <h2 className="header-copy">
          <strong className="main-text">
            This is
            <br />
            The Digital Works what
            <br />
            develops web apps & dashboards,
            <br />
            focus on modern web development.
          </strong>
        </h2>
        <section className="work-list-wrap">
          <KeyProjectBox />
        </section>
        <section className="mid-copy">
          <WorkMidCopy />
          <WorkMidBox />
          <WorkMidThirdCopy />
        </section>
        <hr className="section-line" />
        <section className="other-works-section">
          <h3 className="head">그 밖에 작업들.</h3>
          <p className="sub">Other works</p>
          <div className="workbox-wrap">
            <div className="year-box">
              <YearBox setSelectedYear={setSelectedYear} />
            </div>
            <div className="desc-box">
              <div className="desc-box-inner">
                {projectsForYear.length > 0 ? (
                  projectsForYear.map((item) => (
                    <DescBox key={item.id} project={item} />
                  ))
                ) : (
                  <p style={{ fontSize: "1.8rem" }}>
                    선택된 연도에 해당하는 프로젝트 정보가 없습니다.
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Works;
