const Feeds = () => {
  return (
    <>
      <div className="app-feeds">
        <h2 className="header-copy">
          Deep dive into the latest trends and techniques that are shaping the
          digital landscape
        </h2>
        <ul className="feed-list">
          <li>
            <div className="feed-card">피드 아이템</div>
          </li>
          <li>
            <div className="feed-card">피드 아이템</div>
          </li>
          <li>
            <div className="feed-card">피드 아이템</div>
          </li>
          <li>
            <div className="feed-card">피드 아이템</div>
          </li>
          <li>
            <div className="feed-card">피드 아이템</div>
          </li>
          <li>
            <div className="feed-card">피드 아이템</div>
          </li>
          <li>
            <div className="feed-card">피드 아이템</div>
          </li>
          <li>
            <div className="feed-card">피드 아이템</div>
          </li>
        </ul>
      </div>
    </>
  );
};
export default Feeds;
