import { React } from "react";

function DescBox({ project }) {
  const displayDate =
    project.startDt === project.endDt
      ? project.startDt
      : `${project.startDt}-${project.endDt}`;

  return (
    <dl key={project.id}>
      <dt>
        <strong>{project.title}</strong> / {project.client}{" "}
        <span>{displayDate}</span>
      </dt>
      <dd className="role">
        <em>{project.role}</em>
      </dd>
      <dd className="info">
        <p>{project.description}</p>
        <p>{project.stacks}</p>
      </dd>
    </dl>
  );
}

export default DescBox;
