import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LOGO_RB from "assets/images/logo_rb.svg";

const Header = () => {
  const [headerClass, setHeaderClass] = useState("app-header");
  const location = useLocation();

  useEffect(() => {
    let tempClass = "";
    if (location.pathname === "/works") {
      tempClass = "app-header is-bg-darkgray";
    } else if (
      location.pathname === "/contact" ||
      location.pathname === "/feeds"
    ) {
      tempClass = "app-header is-bg-gray";
    } else {
      tempClass = "app-header";
    }
    console.log("tempClass >>", tempClass);
    setHeaderClass(tempClass);
  }, [location.pathname]);

  const getActiveClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <header className={headerClass}>
      <h1>
        <Link to="/">
          <img src={LOGO_RB} className="app-logo" alt="Routebase" />
        </Link>
      </h1>
      <nav className="app-nav">
        <Link to="/works" className={getActiveClass("/works")}>
          works
        </Link>
        <Link to="/services" className={getActiveClass("/services")}>
          services
        </Link>
        <Link to="/about" className={getActiveClass("/about")}>
          about
        </Link>
        <Link to="/feeds" className={getActiveClass("/feeds")}>
          feeds
        </Link>
        <Link to="/contact" className={getActiveClass("/contact")}>
          contact
        </Link>
      </nav>
    </header>
  );
};

export default Header;
