import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { config } from "config";

const ServiceFrontEnd = () => {
  const [headerClass, setHeaderClass] = useState("app-header");
  const location = useLocation();

  const baseURL = config.baseURL;
  console.log("config ", config);

  useEffect(() => {
    let tempClass = "";
    if (location.pathname === "/works") {
      tempClass = "app-header is-bg-darkgray";
    }
    setHeaderClass(tempClass);
  }, [location.pathname]);

  const getActiveClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <>
      <h2 className="header-copy">
        <strong>라우트베이스</strong>에서 평범함을 넘어{" "}
        <strong>유니크함</strong>을 찾으세요.
        <br />
        혁신적인 프론트엔드 기술로 당신의 아이디어를 실현하세요.
      </h2>
      <h3 className="sub-copy">
        Front-end development on web applications, websites, prototyping,
        dashboards, e-commerces, and more.
      </h3>
      <div className="btn-more">
        {/* <a href={`${baseURL}/works`}>View Key Projects</a> */}
        <Link to="/works" className={getActiveClass("/works")}>
          View Key Projects
        </Link>
      </div>
      <ul className="service-fb-field">
        <li>
          <dl>
            <dt>(Front-end Development)</dt>
            <dd>Vue / React</dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>(UI/UX Design)</dt>
            <dd>Figma</dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>(Back-end)</dt>
            <dd>Node.js / Express.js</dd>
          </dl>
        </li>
      </ul>
    </>
  );
};

export default ServiceFrontEnd;
