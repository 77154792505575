import JUXT from "assets/images/copy_juxt.svg";
import AboutCopy from "components/AboutCopy";
import CircleMotion from "components/CircleMotion";
import AboutFocus from "components/AboutFocus";
import AboutBottomSwiper from "components/AboutBottomSwiper";

const About = () => {
  return (
    <>
      <div className="app-about">
        <h2 className="header-copy">
          <span className="imgbox">
            <img src={JUXT} alt="The Juxtaposition of Life" />
          </span>
          <AboutCopy />
        </h2>
        <h3 className="about-info">
          라우트베이스는 프론트엔드 웹개발과 이미지 생성 AI 서비스를 제공하고
          있습니다.
          <br />
          <span>
            Routebase provides front-end web development and generative AI image
            services.
          </span>
        </h3>
        <p className="about-desc">
          Routebase is a web-based studio specializing in web motion and
          front-end development, with a strong emphasis on integrating
          sophisticated typography and dynamic images to elevate the user
          experience. With over a decade of expertise in UI design, our approach
          is deeply rooted in creating visually compelling and user-friendly
          interfaces. Currently, we are leveraging our extensive UI design
          background to advance in front-end development, employing the latest
          technologies to build responsive, intuitive web applications. This
          blend of design and development expertise ensures that all projects
          are both aesthetically pleasing and technically robust.
          <br />
          <br />
          As a soleproprietorship, we plan to expand into the online marketing
          sector by integrating virtual models through Stable Diffusion's
          ComfyUI by year's end, maintaining a lean operation while exploring
          new digital solutions.
        </p>
        <AboutFocus />
        <CircleMotion />
        <AboutBottomSwiper />
      </div>
    </>
  );
};

export default About;
