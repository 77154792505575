// import React, { useRef } from "react";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { useGSAP } from "@gsap/react";

import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const WorkMidBox = () => {
  const midBoxImg = useRef(null);

  useEffect(() => {
    const element = midBoxImg.current;

    gsap.fromTo(
      element,
      {
        scale: 1.8, // 시작 스케일
      },
      {
        scale: 1.0, // 종료 스케일
        ease: "power1.out", // ease 속성 추가: power1.out은 시작은 빠르고 끝은 천천히 종료
        scrollTrigger: {
          trigger: element,
          start: "top bottom", // 트리거가 뷰포트 상단에 닿을 때
          end: "bottom top", // 트리거가 뷰포트 하단에 닿을 때
          scrub: true, // 스크롤 바를 움직이면서 애니메이션 효과가 자연스럽게 적용됩니다.
          // markers: true, // 개발 중 위치 확인을 위한 마커를 표시
        },
      }
    );
  }, []);

  return (
    <>
      <div className="project-midbox">
        <div ref={midBoxImg} className="project-midbox-img"></div>
        <p>Routebase: Crafting Tomorrow’s Web.</p>
      </div>
    </>
  );
};

export default WorkMidBox;
