import { React } from "react";

const SelectedProject = ({ project }) => {
  // \n 문자를 <br> 태그로 변환하는 함수
  const formatText = (text) => {
    return { __html: text.replace(/\n/g, "<br>") }; // \n을 <br>로 대체
  };
  return (
    <div className="project-infobox">
      <div className="title">{project.title}</div>
      <div className="desc">
        <dl className="desc-unit">
          <dt>Info</dt>
          <dd dangerouslySetInnerHTML={formatText(project.info)} />
        </dl>
        <dl className="desc-unit">
          <dt>Clients</dt>
          <dd dangerouslySetInnerHTML={formatText(project.client)} />
        </dl>
        <dl className="desc-unit">
          <dt>Role</dt>
          <dd>{project.role}</dd>
        </dl>
        <dl className="desc-unit">
          <dt>Tech stacks</dt>
          <dd>{project.stack}</dd>
        </dl>
        <dl className="desc-unit">
          <dt>Duration</dt>
          <dd>
            {project.startDt} - {project.endDt}
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default SelectedProject;
