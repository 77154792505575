import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const ServiceSwiper = React.memo(({ productList }) => {
  return (
    <>
      <div className="middle-sub-desc">
        VisionFlow의 생성 AI 서비스는 헤어 및 뷰티 산업에 혁신을 가져다 주며,
        전문적으로 디자인된 UI로 브랜드 이미지를 한층 더 높여줍니다. 강화된
        콘텐츠 시스템은 소비자가 원하는 정보에 빠르게 접근할 수 있도록 돕습니다.
      </div>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={6}
        centeredSlides={false}
        slidesPerView={8}
        grabCursor={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        speed={1000}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {productList.map((item, index) => (
          <SwiperSlide key={item.id}>
            {" "}
            {/* 여기서 닫는 괄호를 추가합니다 */}
            <div className="swiper-item">
              <img src={item.imgURL} alt={item.title} data-index={index} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
});

export default ServiceSwiper;
