import { v4 as uuidv4 } from "uuid";

export const othersList = [
  {
    year: "2024",
    title: "이투스 청솔학원/강남하이퍼학원 Vue 개발",
    client: "이투스",
    role: "Vue 퍼블리싱",
    startDt: "2024.08",
    endDt: "2024.10",
    description: "HTML to Vue 변환, 신규페이지, UI 컴포넌트 제작",
    stacks: "Nuxt, Vue3, VueUse, Swiper, GSAP, SCSS, Figma, Jira",
  },
  {
    year: "2024",
    title: "K-SURE 사이버영업점 리뉴얼",
    client: "K-SURE",
    role: "퍼블리싱 리더",
    startDt: "2024.05",
    endDt: "2024.07",
    description: "퍼블리싱 가이드 작성, 메인, 대시보드, 기타 서브페이지 코딩",
    stacks: "웹스퀘어, jQuery, Swiper",
  },
  {
    year: "2024",
    title: "라우트베이스 사이트 개발",
    client: "Routebase",
    role: "프론트엔드, 기획, 디자인",
    startDt: "2024.09",
    endDt: "2024.10",
    description: "자체 개발, 이미지 AI생성",
    stacks: "React, ChatGPT, Midjourney, Stable Diffusion, ComfyUI",
  },
  {
    year: "2023",
    title: "반도체 부품 제조 스마트팩토리 구축",
    client: "SK앱솔릭스, SK C&C",
    role: "Vue 프론트엔드",
    startDt: "2023.09",
    endDt: "2023.11",
    description: "MES 프론트엔드 개발, UI/UI 개선, 신규 페이지 개발",
    stacks: "Vue 2, I-FACTs Framwork, Wijmo Grid, Buefy",
  },
  {
    year: "2023",
    title: "KBS News 리뉴얼",
    client: "KBS",
    role: "프론트엔드",
    startDt: "2023.06",
    endDt: "2023.08",
    description: "ES5 기반 프론트엔드 개발",
    stacks: "ES5, JQuery, Firebase, handlebars, JW Player",
  },
  {
    year: "2023",
    title: "KT서비스남부 그룹웨어",
    client: "KTDS",
    role: "퍼블리싱",
    startDt: "2023.04",
    endDt: "2023.05",
    description: "대사우, 서약 퍼블리싱, 결재 문서 줌인 기능",
    stacks: "JS, CSS, dhtmlx, STS, MS-SQL, Git, JSP",
  },
  {
    year: "2022",
    title: "SCP CMP 모니터링대시보드",
    client: "삼성SDS, 에스코어",
    role: "Vue 프론트엔드",
    startDt: "2021.10",
    endDt: "2023.03",
    description: "모니터링대상상세 Vue개발, Event/Agent 일부 개발",
    stacks: "Vue 2, Lego Component, EChart, SCSS, Git, Jira, Confluence / MSA",
  },
  {
    year: "2022",
    title: "암호화폐시세연동조회앱",
    client: "LK컨버전스",
    role: "React 퍼블리싱",
    startDt: "2022.08",
    endDt: "2022.08",
    description: "React 퍼블리싱, UI 컴포넌트 일부 제작",
    stacks: "React, Material UI, Styled Component",
  },
  {
    year: "2021",
    title: "유니포탈 리뉴얼/운영",
    client: "비젠트로",
    role: "퍼블리싱",
    startDt: "2018.05",
    endDt: "2021.09",
    description:
      "그룹웨어 웹 퍼블리싱, JS개발, 매뉴얼작성, 퍼블리싱 교육, 전피연 그룹웨어, 클라우드포탈 퍼블리싱",
    stacks: "HTML5, JS, dhtmlx, JSP, SCSS",
  },
  {
    year: "2020",
    title: "유니포탈 리뉴얼/운영",
    client: "비젠트로",
    role: "퍼블리싱",
    startDt: "2018.05",
    endDt: "2021.09",
    description:
      "그룹웨어 웹 퍼블리싱, JS개발, 매뉴얼작성, 퍼블리싱 교육, 전피연 그룹웨어, 클라우드포탈 퍼블리싱",
    stacks: "HTML5, JS, dhtmlx, JSP, SCSS",
  },
  {
    year: "2019",
    title: "유니포탈 리뉴얼/운영",
    client: "비젠트로",
    role: "퍼블리싱",
    startDt: "2018.05",
    endDt: "2021.09",
    description:
      "그룹웨어 웹 퍼블리싱, JS개발, 매뉴얼작성, 퍼블리싱 교육, 전피연 그룹웨어, 클라우드포탈 퍼블리싱",
    stacks: "HTML5, JS, dhtmlx, JSP, SCSS",
  },
  {
    year: "2018",
    title: "유니포탈 리뉴얼/운영",
    client: "비젠트로",
    role: "퍼블리싱",
    startDt: "2018.05",
    endDt: "2021.09",
    description:
      "그룹웨어 웹 퍼블리싱, JS개발, 매뉴얼작성, 퍼블리싱 교육, 전피연 그룹웨어, 클라우드포탈 퍼블리싱",
    stacks: "HTML5, JS, dhtmlx, JSP, SCSS",
  },
  {
    year: "2018",
    title: "홈상담지원시스템 개발",
    client: "LG유플러스",
    role: "퍼블리싱",
    startDt: "2018.02",
    endDt: "2018.04",
    description: "웹스퀘어 기반 퍼블리싱",
    stacks: "웹스퀘어, HTML5, CSS, JS",
  },
  {
    year: "2018",
    title: "모바일단말물류앱 하이브리드",
    client: "LG유플러스",
    role: "퍼블리싱",
    startDt: "2017.12",
    endDt: "2018.01",
    description: "웹스퀘어 기반 퍼블리싱",
    stacks: "웹스퀘어, HTML5, JS, CSS",
  },
  {
    year: "2017",
    title: "BC카드 대출중개 사이트",
    client: "BC카드",
    role: "퍼블리싱",
    startDt: "2017.09",
    endDt: "2017.11",
    description: "반응형웹 퍼블리싱",
    stacks: "HTML5, CSS, JS, JSP",
  },
  {
    year: "2017",
    title: "현대H&S 임직원몰 모바일",
    client: "현대H&S",
    role: "JS 개발",
    startDt: "2017.05",
    endDt: "2017.08",
    description: "하이브리드 웹 기반 UI 개발",
    stacks: "JS, jQuery, JSP",
  },
  {
    year: "2017",
    title: "KT OTT 스마트 TV",
    client: "KT",
    role: "JS 개발",
    startDt: "2017.02",
    endDt: "2017.04",
    description: "TV셋탑박스 프로토타입 UI개발",
    stacks: "JS, jQuery, SPA",
  },
  {
    year: "2016",
    title: "신한카드 모바일 앱",
    client: "신한카드",
    role: "퍼블리싱 리더",
    startDt: "2016.11",
    endDt: "2017.01",
    description: "모바일 웹",
    stacks: "HTML5, CSS3",
  },
  {
    year: "2016",
    title: "밀폐공간 유해가스 감지시스템",
    client: "SK C&C",
    role: "퍼블리싱, 기획",
    startDt: "2016.09",
    endDt: "2016.11",
    description: "퍼블리싱, 솔루션 기획",
    stacks: "HTML5, CSS3, JS, Bootscrap, Eclipse",
  },
  {
    year: "2016",
    title: "한국전력기술 리뉴얼",
    client: "한국전력기술",
    role: "퍼블리싱",
    startDt: "2016.07",
    endDt: "2016.08",
    description: "반응형 웹 퍼블리싱",
    stacks: "HTML5, CSS, JS",
  },
  {
    year: "2016",
    title: "필라코리아 ERP개발",
    client: "필라코리아",
    role: "퍼블리싱 리더",
    startDt: "2016.03",
    endDt: "2016.06",
    description: "유통 ERP 웹스퀘어 퍼블리싱",
    stacks: "웹스퀘어, CSS, jQuery, jqWidget",
  },
  {
    year: "2015",
    title: "매일유업 경영관리시스템 2차",
    client: "매일유업",
    role: "퍼블리싱",
    startDt: "2015.10",
    endDt: "2015.12",
    description: "웹스퀘어 기반 퍼블리싱",
    stacks: "웹스퀘어, CSS, jQuery, FusionChart",
  },
  {
    year: "2015",
    title: "개인정보보안 모니터링 제안 및 UI개선",
    client: "근로복지공단",
    role: "기획, 디자인, 퍼블리싱",
    startDt: "2015.05",
    endDt: "2015.09",
    description:
      "퍼블리싱, 디자인, 전시회용 영상 제작, 제안기획 (농협손보, BC카드 등)",
    stacks: "HTML5, JS, jQuery, JSP",
  },
  {
    year: "2015",
    title: "퍼피랑키티랑 하이브리드앱",
    client: "메인라인",
    role: "퍼블리싱",
    startDt: "2015.02",
    endDt: "2015.03",
    description: "모바일 퍼블리싱, UI 가이딩, 하이브리드 앱",
    stacks: "HTML, CSS",
  },
  {
    year: "2015",
    title: "폴리몰 구축 및 교육 (베트남)",
    client: "코리아폴리스쿨",
    role: "UX 리더",
    startDt: "2014.11",
    endDt: "2015.02",
    description: "폐쇄몰 디자인, 스타일가이드 작성, 현지팀 셋업, UX교육",
    stacks: "Photoshop",
  },
  {
    year: "2014",
    title: "수출입은행 그룹웨어 개발",
    client: "수출입은행",
    role: "UX 리더",
    startDt: "2014.05",
    endDt: "2014.08",
    description: "그룹웨어 기획, 퍼블리싱 ",
    stacks: "Axure, HTML5. CSS, JS, JSP, FullCalender.js",
  },
  {
    year: "2014",
    title: "대검찰청 국가디지털 증거송치체계",
    client: "대검찰청",
    role: "퍼블리싱 리더",
    startDt: "2014.01",
    endDt: "2014.04",
    description: "IDEAS 웹, 송치체계 퍼블리싱",
    stacks: "HTML5, CSS3, jqWidget, jQuery",
  },
  {
    year: "2014",
    title: "Calyx.kr 사이트개발",
    client: "pxd",
    role: "PM, 퍼블리싱, 디렉팅",
    startDt: "2014.01",
    endDt: "2014.01",
    description: "CSS3 모션 및 웹 퍼블리싱",
    stacks: "HTML5, JS, CSS",
  },
  {
    year: "2013",
    title: "육군응용체계통합",
    client: "육군본부",
    role: "퍼블리싱 리더",
    startDt: "2013.09",
    endDt: "2013.12",
    description: "웹스퀘어 기반 퍼블리싱",
    stacks: "웹스퀘어, HTML, JS, CSS",
  },
  {
    year: "2013",
    title: "KT BIT OSS",
    client: "KT",
    role: "JS 개발",
    startDt: "2013.02",
    endDt: "2013.08",
    description: "UI공통JS설계, JS플러그인개발",
    stacks: "웹스퀘어, JS",
  },
  {
    year: "2012",
    title: "지식제안시스템, 산타클라우드, 삼성토탈",
    client: "DIT",
    role: "퍼블리싱, 기획",
    startDt: "2012.05",
    endDt: "2013.02",
    description:
      "노루페인트 그룹사 퍼블리싱, 세일즈노트 기획, 삼성토탈 아이포탈 기획/퍼블리싱",
    stacks: "HTML5, CSS, jQuery",
  },
  {
    year: "2012",
    title: "KOC 해외유정 보안관리 (쿠웨이트)",
    client: "삼성SDS",
    role: "UX 리더",
    startDt: "2012.01",
    endDt: "2012.04",
    description: "GUI디자인, 프로토타입 디자인, UX 교육",
    stacks: "Photoshop, CSS, HTML",
  },
  {
    year: "2011",
    title: "GS SHOP EC플랫폼",
    client: "GS SHOP",
    role: "UI 분석",
    startDt: "2011.08",
    endDt: "2011.11",
    description: "커머스 UI패턴 분석, 가이드 작성",
    stacks: "HTML, CSS",
  },
  {
    year: "2011",
    title: "LS 모바일오피스",
    client: "LS",
    role: "디자인",
    startDt: "2011.06",
    endDt: "2011.07",
    description: "스토어, 런처, 메일, 일정, 임직원검색 GUI디자인",
    stacks: "Photoshop, Illustrator",
  },
  {
    year: "2011",
    title: "WZON 웹사이트 돈버는앱 개발",
    client: "월드게이트",
    role: "플래시 리더, 기획",
    startDt: "2010.07",
    endDt: "2011.05",
    description:
      "홈페이지 템플릿 가이드라인 작성, 사용자가이드 플래시 개발, 돈버는앱 기획",
    stacks: "Flash, Photoshop, AfterEffect, AS 3.0",
  },
  {
    year: "2010",
    title: "WZON 웹사이트 돈버는앱 개발",
    client: "월드게이트",
    role: "플래시 리더, 기획",
    startDt: "2010.07",
    endDt: "2011.05",
    description:
      "홈페이지 템플릿 가이드라인 작성, 사용자가이드 플래시 개발, 돈버는앱 기획",
    stacks: "Flash, Photoshop, AfterEffect, AS 3.0",
  },
  {
    year: "2009",
    title: "OnSpeaking 사이트",
    client: "케이세스",
    role: "디자인, 기획",
    startDt: "2009.06",
    endDt: "2009.07",
    description: "디자인/기획	LMS기획, 디자인, 코딩",
    stacks: "Photoshop, HTML, CSS, Flash",
  },
  {
    year: "2008",
    title: "OnSpeaking 사이트",
    client: "노원구청",
    role: "디자인",
    startDt: "2008.10",
    endDt: "2008.11",
    description: "디자인/기획	LMS기획, 디자인, 코딩",
    stacks: "Photoshop, HTML, CSS, Flash",
  },
  {
    year: "2008",
    title: "여수시청 사이버학습센터",
    client: "여수시청",
    role: "디자인",
    startDt: "2008.05",
    endDt: "2008.09",
    description: "디자인/기획	LMS기획, 디자인, 코딩, 플래시",
    stacks: "Photoshop, HTML, CSS, Flash",
  },
  {
    year: "2007",
    title: "유세븐 쇼핑몰 개발",
    client: "와일드알프코리아",
    role: "PM, 디자인",
    startDt: "2007.09",
    endDt: "2008.04",
    description: "웹기획, 유아용품 쇼핑몰 기획, 디자인",
    stacks: "Photoshop, HTML, CSS, Flash, PHP",
  },
  {
    year: "2007",
    title: "CJ 지식포탈 UI개선",
    client: "CJ",
    role: "디자인 리더",
    startDt: "2007.04",
    endDt: "2007.04",
    description:
      "UI 디자인, 포커스그룹 인터뷰, 온라인서베이 활용 프로토타입 도출",
    stacks: "Photoshop, HTML",
  },
  {
    year: "2007",
    title: "옐로우필름 리뉴얼, 하나로텔레콤 청약 개선",
    client: "고객 다수",
    role: "디자인 리더",
    startDt: "2007.01",
    endDt: "2007.03",
    description: "디자인 리더, 플래시",
    stacks: "Photoshop, Flash",
  },
  {
    year: "2006",
    title: "M35 DMB 사이트",
    client: "메리테크",
    role: "디자인 리더",
    startDt: "2006.10",
    endDt: "2006.10",
    description: "플래시PM, 플래시모션, UI디자인, 제품/모델 촬영 진행",
    stacks: "Photoshop, Flash, AS 2.0",
  },
  {
    year: "2006",
    title: "테팔코리아/존슨앤드존슨",
    client: "테팔코리아",
    role: "디자인 리더",
    startDt: "2006.06",
    endDt: "2006.10",
    description: "입소문마케팅 배너 개발	플래시",
    stacks: "Photoshop, Flash, AS 2.0",
  },
  {
    year: "2006",
    title: "팬텍 글로벌",
    client: "팬텍",
    role: "디자인 리더",
    startDt: "2006.05",
    endDt: "2006.06",
    description: "유지보수 총괄, 스타일가이드 작성, 마이크로사이트 개발	",
    stacks: "Photoshop, Flash, AS 2.0",
  },
  {
    year: "2006",
    title: "농수산물 유통공사 리뉴얼",
    client: "농수산물 유통공사",
    role: "디자인 리더",
    startDt: "2006.03",
    endDt: "2006.05",
    description: "UI 디자인, 플래시모션, 메인디자인, CMS기반 코딩",
    stacks: "Photoshop, Flash, HTML, CSS",
  },
  {
    year: "2005",
    title: "LIG CI컨텐츠 개발, 대전교육포탈 제안, 한양사이버대 입시",
    client: "LIG, LG CNS, 한양사이버대",
    role: "플래시 리더",
    startDt: "2005.05",
    endDt: "2006.02",
    description:
      "이러닝컨텐츠 차시개발, LG CNS 대전교육포탈 제안 및 UI기획, 한양사이버대 입시사이트 디자인",
    stacks: "Photoshop, Flash, HTML, JS, CSS",
  },
  {
    year: "2004",
    title: "KT리뉴얼",
    client: "KT",
    role: "디자인, 플래시",
    startDt: "2004.10",
    endDt: "2005.04",
    description: "KT 사이트 운영, CEO이용경 사이트 개발, KT매니아 플래시",
    stacks: "Photoshop, Flash, HTML, JS, AS 2.0 ",
  },
  {
    year: "2004",
    title: "PiFan 2004",
    client: "부천판타스틱영화제",
    role: "플래시",
    startDt: "2004.08",
    endDt: "2004.08",
    description: "부천판타스틱영화제 사이트 플래시 모션",
    stacks: "Flash",
  },
  {
    year: "2004",
    title: "고스트스테이션 운영",
    client: "신해철",
    role: "디자인",
    startDt: "2003.11",
    endDt: "2004.01",
    description: "가수 신해철 인터넷 방송국 사이트 디자인, 다이어리 디자인",
    stacks: "Photoshop, Flash, Illustrator",
  },
  {
    year: "2003",
    title: "5d5book.com, 영화 영매, 정원영",
    client: "유미디어드림, M&F",
    role: "디자인, 플래시",
    startDt: "2003.02",
    endDt: "2003.10",
    description:
      "인디영화 영매, 뮤지션 정원영 마이크로사이트 디자인, 플래시 모션, 5d5book.com 사이트/홍보물 디자인",
    stacks: "Photoshop, Flash, HTML, CSS, Dreamweaver, AS 2.0",
  },
  {
    year: "2003",
    title: "실험 타이포그래피 과제전",
    client: "개인 작품",
    role: "디자인",
    startDt: "2003.03",
    endDt: "2003.06",
    description:
      "상명대학교 예술디자인대학원 실험타이포그라피, Micro/Macro 무빙타이포, 오페라 라트라비아타 포스터, 정보 미디어키드 디자인",
    stacks: "Photoshop, Illustrator, QuarkXPress",
  },
  {
    year: "2002",
    title: "도서출판 해창 창업",
    client: "Haechang Publisher",
    role: "디자인",
    startDt: "2002.01",
    endDt: "2002.12",
    description:
      "2002년 공동창업, 서울/경기권 유치원, 어린이집 대상 무가지 창간작업, 멀티테라피 작가, 기자 섭외, 예산문제로 중단",
    stacks: "Photoshop, Illustrator, QuarkXPress",
  },
  {
    year: "2001",
    title: "미 인디애나주 의원 대상 전자의료차트 개발",
    client: "메디다스",
    role: "디자인",
    startDt: "2001.01",
    endDt: "2001.07",
    description: "GUI 디자인, CSS 레퍼런스 문서작성",
    stacks: "Photoshop, HTML, CSS, JS, ASP",
  },
  {
    year: "2000",
    title: "롯데호텔제주 리뉴얼, LG전자 TV리뉴얼",
    client: "롯데호텔, LG전자",
    role: "디자인, 플래시",
    startDt: "2000.09",
    endDt: "2000.11",
    description: "디자인, 플래시모션",
    stacks: "HTML, CSS, Photoshop, Dreamweaver",
  },
  {
    year: "1999",
    title: "Jump2000 개발/운영, 남도학숙 리뉴얼",
    client: "프로웨어",
    role: "기획, 디자인, 코딩",
    startDt: "1999.08",
    endDt: "2000.08",
    description: "Jump2000 학원관리솔루션 사이트 디자인, 기획, 홍보물디자인",
    stacks: "HTML, CSS. JS, Dreamweaver, Corel",
  },
].map((project) => ({
  ...project,
  id: uuidv4(),
}));
