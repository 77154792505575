const Main = () => {
  return (
    <main className="app-main">
      <div className="main-links">
        <div className="main-link-ai">
          <div className="main-copy">
            Generative AI
            <br />
            Image Services
          </div>
        </div>
        <div className="main-link-fb">
          <div className="main-copy">
            front-end
            <br /> web development
          </div>
        </div>
        <div className="main-link-info">
          Routebase provides
          <br />
          front-end web development
          <br />
          and generative AI image services.
        </div>
      </div>
    </main>
  );
};

export default Main;
