import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const AboutBottomSwiper = ({}) => {
  return (
    <>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={160}
        centeredSlides={true}
        slidesPerView={3}
        grabCursor={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        speed={1000}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <div className="swiper-item">
            <h5>Reflection</h5>
            <p>
              저희의 모든 작업은 고객과의 긴밀한 협업하에 이루어지며, 이는 모든
              단계에서 고객의 요구를 정확히 반영합니다.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-item">
            <h5>Customization</h5>
            <p>
              생성 AI 기술을 활용하여, 고객의 니즈에 맞춘 맞춤형 UX 디자인과
              프론트엔드 개발을 제공하며, 이를 통해 놀라운 사용자 경험을
              창출합니다.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-item">
            <h5>Discovery</h5>
            <p>
              모션 디자인과 상호작용을 중심으로 한 우리의 접근 방식은 사용자가
              우리의 웹사이트와 앱에서 매 순간마다 새로운 발견을 경험하게
              합니다.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-item">
            <h5>Storytelling</h5>
            <p>
              각 인터랙션은 사용자에게 감동을 주는 독특한 이야기로 변환되며,
              이는 라우트베이스의 디자인 철학이자 우리가 추구하는 예술적
              실천입니다.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-item">
            <h5>Enhancement</h5>
            <p>
              디지털 아트와 프론트엔드 기술의 결합을 통해, 우리는 각
              클라이언트의 브랜드 아이덴티티를 강화하고 시장에서의 경쟁력을
              높입니다.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-item">
            <h5>Precision</h5>
            <p>
              사용자 경험을 중심으로 한 모든 디자인은 정교하게 계획되고
              실행되며, 이는 라우트베이스의 창의적 아이덴티티를 구축하는 데
              기여합니다.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-item">
            <h5>Vision</h5>
            <p>
              라우트베이스의 모든 프로젝트는 고객의 비전과 밀접하게 연결되어
              있으며, 각각의 성공이 저희의 성공입니다.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default AboutBottomSwiper;
