import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SmoothScrolling from "components/SmoothScrolling";
// import Scrollbar from "smooth-scrollbar";
// import { ReactLenis, useLenis } from "lenis/react";
import { config } from "config";

const baseURL = config.baseURL || "http://routebase.info";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <React.StrictMode>
      <SmoothScrolling>
        <App />
      </SmoothScrolling>
    </React.StrictMode>
  </BrowserRouter>
);
reportWebVitals();
